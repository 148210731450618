.hidden {
  opacity: 0;
  filter: blur(5px);
  transition: all .5s;
}

.hidden_left {
  transform: translateX(-100px);
}

.hidden_right {
  transform: translateX(100px);

}

.delay:nth-child(1) {
  transition-delay: 200ms;
}
.delay:nth-child(2) {
  transition-delay: 400ms;
}
.delay:nth-child(3) {
  transition-delay: 600ms;
}
.delay:nth-child(4) {
  transition-delay: 800ms;
}

.show {
  opacity: 1;
  filter: blur(0);
  transform: translateX(0);
}